<script setup lang="ts">
import { chatService } from '../../services';
import CartItemComp from './cart-item.component.vue';
import { CartItem, CartList } from './cart-list.model';
import { store } from '../../models';

const props = defineProps<{ items: CartItem[], messageId: string }>();
const emit = defineEmits(['add']);

const state = new CartList();
state.items = props.items;

const calculateTotal = () => {
  state.total = Math.ceil(state.items.reduce((sum, item) => (sum += item.selectedItem.price, sum), 0) * 100) / 100;
}
calculateTotal();

const onDelete = async (index: number ) => {
  const chatId = store.chats[store.activeChatIndex].id;
  const messageId = props.messageId;
  const itemId = state.items[index].selectedItem.id;
  const message = await chatService.deleteItem(chatId, messageId, itemId);
  store.replaceMessage(message);
}
const onSelect = async (altIndex: number, index: number) => {
  const chatId = store.chats[store.activeChatIndex].id;
  const messageId = props.messageId;
  const itemId = state.items[index].selectedItem.id;
  const newItemId = state.items[index].alternatives[altIndex].id;
  const message = await chatService.replaceItem(chatId, messageId, itemId, newItemId);
  store.replaceMessage(message);
}
</script>

<template>
  <div class="fc-cart-list">
    <div v-for="(item, index) in state.items" :key="index">
      <CartItemComp :item="item" @delete="onDelete(index)" @select="onSelect($event, index)"></CartItemComp>
    </div>
  </div>
  <div class="fc-cart-list__add">
    <Icon name="add" @click="emit('add')"></Icon>
  </div>
  <div class="fc-cart-list__total">
    <b>total</b>: ${{ state.total }}
  </div>
</template>

<style scoped lang="scss">
  .fc-cart-list {
    padding: 10px 0;

    &__add {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 10px;
      cursor: pointer;
      color: green;
      opacity: 0.5;
      transition: 300ms opacity;
      padding-left: 10px;

      &:hover {
        opacity: 1;
      }

      svg {
        cursor: pointer;
      }
    }

    &__total {
      float: right;
      padding-right: 20px;
    }
  }
</style>
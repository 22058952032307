<script>
import { h } from 'vue';
import { Suggestions, List } from '../';

const components = {
  List,
  Suggestions
};

export default {
  props: {
    template: {
      type: String,
      default: ''
    },
    props: {
      type: Object
    }
  },
  render() {
    return h({
        components,
        template: this.template,
        props: this.props ? Object.keys(this.props) : undefined
      },
      this.props
    );
  },
};
</script>
<script setup lang="ts"></script>

<template>
  <Teleport to="body">
    <div class="fc-modal__back">
      <div class="fc-modal">
        <div class="fc-modal__header">
          <slot name="header"></slot>
        </div>
        <div class="fc-modal__content" ref="el">
          <slot></slot>
        </div>
        <div class="fc-modal__footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style scoped lang="scss">
.fc-modal {
  position: absolute;
  left: calc(50% - 220px);
  top: 50%;
  width: 400px;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 7px 40px 2px rgba(148, 149, 150, .3);
  z-index: 1000;
  border-radius: 10px;
  background: #fff;

  font-weight: 300;
  font-size: 14px;
  -webkit-font-smoothing: subpixel-antialiased;

  &__back {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(0 0 0 / 50%);
    z-index: 1000;
  }

  &__footer {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>

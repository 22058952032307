import { Message } from "../models";
import { MessageDTO } from "../services/chat.service";

export const getMessageFromMessageGTO = (messages: MessageDTO[]): Message[] => {
  return messages.map(message => ({
    id: message.id,
    type: message.type === 'html' ? 'text' : message.type,
    data: { ...message.data, text: message.text || '' },
    author: message.role === 'user' ? 'me' : 'support'
  }));
}

export const log = (text: string) => {
  console.debug(text);
}
import { reactive, watch } from "vue";
import { ChatDTO, chatService, MessageDTO, RequestDTO } from "../services";
import { getMessageFromMessageGTO, log } from "../utils/tools";

export class Message{
  id: string;
  isLoading?: boolean;
  suggestions?: string[];
  author?: 'support' | 'me';
  type: MessageDTO["type"];
  data: MessageDTO["data"];
}

interface Store {
  chats: ChatDTO[];
  activeChatIndex: number;
  participants: string[];
  isOpen: boolean;
  messages: Message[];
  clickEvent: Event | undefined;
  extended: number;
}

interface ChatConfig {
  extended: number;
}

export class ChatStore {
  state = reactive<Store>({
    isOpen: false,
    chats: [],
    activeChatIndex: 0,
    messages: [],
    participants: [],
    clickEvent: undefined,
    extended: 0
  });

  get isOpen() {
    return this.state.isOpen;
  }
  set isOpen(value: boolean) {
    log('state.isOpen changed');
    this.state.isOpen = value;
  }

  get chats() {
    return this.state.chats;
  }
  set chats(value: ChatDTO[]) {
    log('state.chats changed');
    this.state.chats = value;
  }

  get messages() {
    return this.state.messages;
  }
  set messages(value: Message[]) {
    log('state.messages changed');
    this.state.messages = value;
  }

  get participants() {
    return this.state.participants;
  }
  set participants(value: string[]) {
    log('state.participants changed');
    this.state.participants = value;
  }

  get activeChatIndex() {
    return this.state.activeChatIndex;
  }
  set activeChatIndex(value: number) {
    log('state.activeChatIndex changed');
    this.state.activeChatIndex = value;
  }

  get clickEvent() {
    return this.state.clickEvent;
  }
  set clickEvent(value: Event | undefined) {
    this.state.clickEvent = value;
  }

  get extended() {
    const config = this.getConfig();
    this.state.extended = config?.extended ? config.extended : 0;
    return this.state.extended;
  }
  set extended(value: number) {
    log('state.extended changed');
    this.state.extended = value;
    this.saveConfig('extended', value);
  }

  subscribe(cb: (data: any) => void, param?: string) {
    watch(this.state, (state) => cb(param ? state[param] : state));
  }

  message(text: string) {
    this.messages.push({
      id: '',
      author: 'me',
      type: 'text',
      data: {
        text
      }
    });
  }

  async asyncMessage(promise: Promise<MessageDTO>, text?: string) {
    this.messages.push({
      id: '',
      author: 'support',
      type: 'text',
      isLoading: true,
      data: {
        text: text || '...'
      }
    });
    let response = await promise;
    if (response.type === 'text' || response.type === 'html') {
      this.state.messages[this.state.messages.length - 1] = getMessageFromMessageGTO([response])[0];
    } else {
      if (response.type === 'api_request' && response.data.requests) {
        const responses = await chatService.actions(response.data.requests, (loadingText) => {
          this.state.messages[this.state.messages.length - 1].data.text = loadingText;
        });
        this.state.messages = this.state.messages.slice(0, -1);
        return this.asyncMessage(chatService.sendMessage({ type: 'api_response', text: undefined, data: { responses }}));
      }
      if (response.type === 'local_storage_request' && response.data.key) {
        const value = localStorage.getItem(response.data.key);
        this.state.messages = this.state.messages.slice(0, -1);
        return this.asyncMessage(chatService.sendMessage({ type: 'local_storage_response', text: undefined, data: { [response.data.key]: value }}));
      }
      if (response.type === 'redirect' && response.data.url) {
        this.state.messages = this.state.messages.slice(0, -1);
        window.location.href = response.data.url;
      }
    }
  }

  replaceMessage(messageDTO: MessageDTO) {
    const message = getMessageFromMessageGTO([messageDTO])[0];
    const index = this.state.messages.findIndex((message_) => message_.id === message.id);
    this.state.messages[index] = message;
  }

  deleteMessage(id: string) {
    this.state.chats = this.state.chats.filter((chat) => chat.id !== id);
  }

  private getConfig() {
    const configString = localStorage.getItem('FC_CONFIG');
    if (configString) {
      return JSON.parse(configString) as ChatConfig;
    }
    return {} as ChatConfig;
  }

  private saveConfig(key: string, value: any) {
    const config = this.getConfig();
    config[key] = value;
    localStorage.setItem('FC_CONFIG', JSON.stringify(config));
  }
}

export const store = new ChatStore();
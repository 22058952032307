<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { Compile } from '@components/common';

const props = defineProps<{ name: string }>();

const getIcon = async (name: string) => {
  let svg = '';
  try {
    svg = await require(`./icons/${name}.svg`).default;
  } catch (e) {}
  return svg;
};

let icon = ref();
onMounted(async () => {
  icon.value = await getIcon(props.name);
});
</script>

<template>
  <compile v-if="icon" :template="icon"></compile>
</template>
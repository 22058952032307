import { reactive } from "vue";
import { log } from "../../utils/tools";

export interface Item {
  id: string;
  imageUrl: string;
  name: string;
  price: number;
}

export interface CartItem {
  alternatives: Item[];
  selectedItem: Item,
}

interface CartListState {
  items: CartItem[];
  total: number;
}

export class CartList {
  private state = reactive<CartListState>({
    items: [],
    total: 0
  });

  get items() {
    return this.state.items;
  }
  set items(value: CartItem[]) {
    log('items changed');
    this.state.items = value;
  }

  get total() {
    return this.state.total;
  }
  set total(value: number) {
    log('total changed');
    this.state.total = value;
  }
}
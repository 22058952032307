<script setup lang="ts">
const props = defineProps<{ items: string[] }>();
const emit = defineEmits(['select']);
</script>

<template>
  <div class="fc-suggestions">
    <div class="fc-suggestions__item" v-for="(item, index) in props.items" :key="index">
      <span @click="emit('select', item)">{{ item }}</span>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .fc-suggestions {
    padding: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-weight: 300;
    font-size: 14px;

    &__item {
      padding: 5px 10px;
      background: rgb(181 207 255);
      border-radius: 6px;
      margin-right: 10px;
      cursor: pointer;
    }
  }
</style>
const AUTH_TOKEN = localStorage.getItem('FC_AUTH');

class ApiService {
  
  async get(path: string) {
    if (AUTH_TOKEN) {
      const resp = await fetch(path, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${AUTH_TOKEN}`
        },
      });
      return await resp.json();
    }
    return Promise.resolve();
  }

  async post(path: string, body: any) {
    if (AUTH_TOKEN) {
      const resp = await fetch(path, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${AUTH_TOKEN}`
        },
        body: JSON.stringify(body)
      });
      return await resp.json();
    }
    return Promise.resolve();
  }

  async put(path: string, body: any) {
    if (AUTH_TOKEN) {
      const resp = await fetch(path, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${AUTH_TOKEN}`
        },
        body: JSON.stringify(body)
      });
      return await resp.json();
    }
    return Promise.resolve();
  }

  async delete(path: string, body?: any) {
    if (AUTH_TOKEN) {
      const resp = await fetch(path, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${AUTH_TOKEN}`
        },
        body: body ? JSON.stringify(body) : null
      });
      return resp.status === 200 ? await resp.json() : null;
    }
    return Promise.resolve();
  }
}

export const apiService = new ApiService();
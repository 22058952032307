<script setup lang="ts">
import { Dropdown } from '@components/common';
import { CartItem } from './cart-list.model';

const { item } = defineProps<{item: CartItem}>();
const emit = defineEmits(['delete', 'select']);
</script>

<template>
  <div class="fc-cart-item">
    <div class="fc-cart-item__wrapper">
      <img class="fc-cart-item__img" :src="item.selectedItem.imageUrl" />
      <div class="fc-cart-item__name">{{ item.selectedItem.name }}</div>
      <div class="fc-cart-item__price">${{ item.selectedItem.price }}</div>
      <Dropdown>
        <template #header>
          <Icon class="fc-cart-item__alternative-open" name="angle-down"></Icon>
        </template>
        <div class="fc-cart-item__alternative">
          <div class="fc-cart-item__alternative-item" v-for="(alt, index) in item.alternatives" :key="index" @click="emit('select', index)">
            <img class="fc-cart-item__alternative-img" :src="alt.imageUrl" />
            <span class="fc-cart-item__alternative-name">{{ alt.name }}</span>
            <span>${{ alt.price }}</span>
          </div>
        </div>
      </Dropdown>
    </div>
    <div class="fc-cart-item__delete" @click="emit('delete')">
      <Icon name="trash"></Icon>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .fc-cart-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 2px;

    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      background: #fff;
      border: 1px solid #cdcdcd;
      border-radius: 10px;
      padding: 10px;
    }

    &:first-child {
      border-radius: 6px 6px 0 0;
    }
    &:last-child {
      border-radius: 0 0 6px 6px;
    }

    &__img{
      width: 60px;
    }

    &__name {
      flex-grow: 1;
      padding: 0 20px;
    }

    &__price {
      margin-right: 10px;
    }

    &__delete {
      cursor: pointer;
      color: red;
      opacity: 0.5;
      transition: 300ms opacity;
      padding-left: 10px;

      &:hover {
        opacity: 1;
      }
    }

    &__alternative {
      width: 300px;
      margin-left: -300px;
      box-shadow: 0px 0px 11px -4px #3f3f3f;
      background: #fff;
      border-radius: 10px;

      &-open {
        cursor: pointer;
      }

      &-img {
        width: 60px;
        margin-right: 10px;
      }

      &-item {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        background: #fff;
        width: 300px;
        padding: 10px;
        cursor: pointer;

        &:first-child {
          border-radius: 10px 10px 0 0;
        }

        &:last-child {
          border-radius: 0 0 10px 10px;
        }

        &:hover {
          background: #ccc;
        }
      }

      &-name {
        margin-right: 5px;
      }
    }
  }
</style>

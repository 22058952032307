import { createApp } from 'vue/dist/vue.esm-bundler';
import Chat from '../libs/vue3-beautiful-chat/src/index';
import Icon from  './assets/icon.component.vue';
import App from './app/app.component.vue';

class AppBootstrap {
  constructor() {
    this.initApp()
  }

  initApp() {
    createApp(App)
      .use(Chat)
      .component('Icon', Icon)
      .mount("#app") 
  }
}

new AppBootstrap()